import styled from 'styled-components';
import bg from '../../images/noshow-bg.png';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const SalesQuoteStyles = styled.div`
  min-height: calc(100vh - var(--footer-height, 0));
  background-color: ${colors.gray[900]};
  color: ${colors.base.white};
  padding-top: 0;
  padding-bottom: 96px;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .fs-16 {
    font-size: 16px;
  }

  .top-nav {
    display: flex;
    justify-content: center;
    padding: 60px 0;

    @media (max-width: 768px) {
      padding-bottom: 23px;
    }

    svg {
      width: 210px;
      height: 31px;
    }
  }

  input {
    ${font('text', 'sm', '400')}
    color: #667085;
    background: ${colors.base.white};
    border: 1px solid ${colors.gray[300]};
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  button {
    ${font('display', 'xs', '700')}
    color: ${colors.base.white};
    background-color: ${colors.green[600]};
    border-radius: 6px;
    margin-bottom: 32px;
  }

  .header-wrapper {
    text-align: center;
    max-width: 680px;
    margin: 0 auto;
    margin-bottom: 64px !important;

    .header {
      ${font('display', 'xxl', '700')}
      color: ${colors.base.white};

      @media (max-width: 768px) {
        ${font('display', 'xl', '700')}
      }

      @media (max-width: 575px) {
        ${font('display', 'lg', '700')}
      }
    }

    .sub-head {
      ${font('text', 'xl', '400')}
      color: ${colors.gray[200]};
      margin-top: 24px;
      margin-bottom: 0;

      @media (max-width: 768px) {
        ${font('text', 'lg', '400')}
      }

      @media (max-width: 575px) {
        ${font('text', 'sm', '400')}
      }
    }
  }

  .password-grid {
    width: 100%;
    display: flex;
    gap: 24px;

    > :first-child {
      flex: 1 1 auto;
    }

    > :last-child {
      flex: 0 0 auto;
    }
  }

  .form-kicker {
    ${font('overline', 'sm', '900')}
    color: #7b848f;
    text-align: center;
    margin-bottom: 16px;
  }

  .form-title {
    ${font('display', 'md', '700')}
    text-align: center;
    margin-bottom: 32px;

    @media (max-width: 575px) {
      font-size: 22.5px;
    }
  }

  .form {
    padding: 48px;
    max-width: 570px;
    color: ${colors.gray[900]};
    background: ${colors.base.white};
    box-shadow: 0 24px 48px -12px rgba(19, 48, 205, 0.25);
    border-radius: 12px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 48px 32px;
    }

    @media (max-width: 575px) {
      padding: 48px 24px;
      min-height: fit-content;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .form-group {
        margin: 0;
      }

      .line-items {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .header,
        .item {
          ${font('text', 'sm', '700')}
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          > :last-child {
            flex: 0 0 120px;
          }
        }

        .header {
          margin-bottom: 12px;

          h3 {
            ${font('display', 'sm', '700')}
          }
        }
      }

      label {
        display: block;
        ${font('text', 'xs', '700')}
        color: ${colors.gray[700]};
        margin-bottom: 6px;

        &.multi {
          display: flex;
          gap: 32px;
          justify-content: space-between;
        }

        .count {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          color: ${colors.primary[600]};
        }
      }

      input {
        display: block;
        height: auto;
        padding: 10.5px 14px;
        border-color: ${colors.gray[300]};
        border-radius: 6px;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0;
        color: ${colors.gray[900]};

        &::placeholder {
          color: ${colors.gray[600]};
        }

        &:disabled {
          color: rgba(0, 0, 0, 0.38);
          background: ${colors.gray[100]};

          &::placeholder {
            color: rgba(0, 0, 0, 0.38);
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 1px ${colors.gray[300]};
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.gray[300]};
      }

      .MuiSelect-select {
        padding-right: 48px;
      }

      .required {
        color: ${colors.red[600]};
        cursor: default;
        text-decoration: none;
      }

      p {
        margin: 0;
      }
    }
  }

  .gated-form {
    padding: 48px;
    max-width: 570px;
    min-height: 525px;
    color: ${colors.gray[900]};
    text-align: center;
    background: ${colors.base.white};
    box-shadow: 0 24px 48px -12px rgba(19, 48, 205, 0.25);
    border-radius: 12px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 48px 32px;
    }

    @media (max-width: 575px) {
      padding: 48px 24px;
      min-height: fit-content;
    }

    img {
      height: 47px;
      margin-bottom: 16px;
    }

    .title-wrapper {
      text-align: center;
      margin-bottom: 24px;

      .form-title {
        margin-bottom: 16px;
      }

      .sub-title {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.16px;
        color: ${colors.gray[600]};
        margin-bottom: 0;
      }
    }

    .form-content {
      .input-row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .input-wrapper {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 100%;

          &:first-of-type {
            margin-right: 16px;
          }

          .form-group {
            margin-bottom: 24px !important;
          }

          label {
            font-weight: 500;
            font-size: 14.4px;
            line-height: 150%;
            color: ${colors.gray[700]};

            &:after {
              content: '*';
              color: var(--critical-color);
              vertical-align: middle;
            }
          }
        }
      }

      button {
        margin-bottom: 0;
      }
    }
  }

  .report-wrapper {
    max-width: 948px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 900px) {
      padding: 0 20px;
    }

    &.show-body {
      section,
      .restart,
      .result-cards .result-card {
        opacity: 1;
          transform: none;
      }
    }

    section {
      width: 100%;
      margin: 0 auto;
      opacity: 0;
      transition: opacity 1s ease 1s;
    }

    .hero {
      opacity: 1;
      margin-top: 20vh;
      transition: margin-top 0.75s ease-in-out 0.75s;

      @media (max-width: 876px) {
        margin-top: 0;
      }

      &.raise {
        margin-top: 0;
      }

      .amount {
        font-weight: 700;
        font-size: 72px;
        line-height: 110%;
        letter-spacing: -0.3px;
        color: ${colors.green[400]};
        margin-bottom: 0;

        @media (max-width: 575px) {
          font-size: 60px;
        }
        
        small {
          font: inherit;
          font-size: 36px;
        }

        @keyframes pop {
          0% {
            transform: none;
          }

          50% {
            transform: scale(1.25);
          }

          100% {
            transform: none;
          }
        }

        &.pop {
          animation: pop ease-in-out 0.25s both;
        }
      }
    }

    .result-cards {
      opacity: 1;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      gap: 24px;
      justify-content: center;
      text-align: left;

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }

      .result-card {
        ${font('text', 'sm', '400')}
        color: ${colors.gray[900]};
        background: ${colors.base.white};
        border-radius: 15px;
        padding: 32px;
        opacity: 0;
        transform: translateY(80px);
        transition: opacity 0.25s ease 0.25s, transform 0.5s ease 0.25s;

        &:nth-child(2) {
          transition-delay: 0.35s;
        }

        &:nth-child(3) {
          transition-delay: 0.45s;
        }

        hr {
          margin: 24px 0;
          border-color: ${colors.gray[200]};
        }

        ul {
          list-style: none;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 12px;

          li {
            color: ${colors.gray[700]};

            .price {
              color: ${colors.green[400]};

              s {
                color: ${colors.gray[400]};
              }
            }
          }
        }
      }
    }

    .result-breakdown {
      list-style: none;
      margin: 0;
      color: ${colors.base.light};
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        padding: 32px;
        background-color: ${colors.gray[700]};
        border-radius: 8px;
        display: flex;
        gap: 24px;
        justify-content: space-between;
        text-align: left;
        align-items: center;

        .label,
        .value {
          ${font('display', 'md', '600')}
        }
      }
    }

    .restart {
      display: inline-block;
      background-color: ${colors.gray[700]};
      color: ${colors.base.white};
      border: none;
      opacity: 0;
      transition: opacity 1s ease 1s;

      &-container {
        width: auto;
        margin: 0 auto;
      }
    }
  }
`;
